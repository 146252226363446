import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function TestResults() {
  const { state: locationState } = useLocation();
  const {
    ClassID,
    MaxMarks,
    ClassModel,
    PassingPercentage,
    SchoolID,
    Subject,
    SubjectVariation,
    SubjectID,
    TestDate,
    Session,
    id,
  } = locationState.selectedTest;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [students, setStudents] = useState([]);
  const [obtainedPercentage, setObtainedPercentage] = useState(null);
  const [schoolIdentity, setSchoolIdentity] = useState(null);
  const [school, setSchool] = useState("");
  const { accesLevel, access } = useSession();
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [missingStudentData, setMissingStudentData] = useState({
    studentId: 0,
    studentName: "",
    className: "",
    activeSession: "",
    obtainedMarks: 0,
    isPresent: false,
  });
  const [GRNO, setGRNO] = useState(0);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedStudentId, setDeletedStudentId] = useState(0);

  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolID = accessObject[0].SchoolId;
      // console.log("school id", schoolID);
      setSchoolIdentity(schoolID);
      if (Object.keys(locationState.selectedTest).length > 0) {
        // console.log("schoolId", schoolID);
        _fetchStudentsByClass(schoolID, ClassID);
      }
    }
    if (accesLevel === "ADMIN") {
      const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
        .SchoolId;
      // console.log("school id", schoolID);
      setSchoolIdentity(schoolID);
      if (Object.keys(locationState.selectedTest).length > 0) {
        // console.log("schoolIdAsad", id);
        // console.log("ClassIdAsad", ClassID);
        _fetchStudentsByClass(schoolID, ClassID);
      }
    }
  }, []);

  useEffect(() => {
    console.log("locationState", locationState);
    console.log("students", students);
  }, []);

  const _fetchStudentsByClass = async (schoolId, classId) => {
    try {
      // console.log(schoolId, id);
      // console.log(
      //   "School ID is : ",
      //   schoolId,
      //   "The id is : ",
      //   id,
      //   " The session is : ",
      //   Session
      // );
      const { data: testResponse, status } = await axiosInstance.get(
        `/test-results/get-test-results-by-school/${schoolId}/${id}`
      );
      // console.log("testResponse", testResponse.data);
      if (testResponse.data.length === 0) {
        console.log(schoolId, classId, Session, "schoolId,classId,Session");
        const { data, status } = await axiosInstance.get(
          `/students/by-school-class/${schoolId}/${classId}/${Session}`
        );
        if (data.data.length > 0) {
          const transformData = data.data.map((item) => {
            return {
              TestID: id,
              MaxMarks: MaxMarks,
              PassingPercentage: PassingPercentage,
              Subject: Subject,
              TestDate: TestDate,
              Present: true,
              ObtainedMarks: 0,
              SID: item.SID,
              GRNO: item.GRNO,
              CurrentSession: item.CurrentSession,
              ActiveSession: item.ActiveSession,
              StudentName: item.StudentName,
              SchoolID: schoolId,
            };
          });
          console.log("In if test result data", data.data);
          setStudents(transformData);
          console.log("transformData", transformData);
          // setShowDeleteButton(false);
        }
      } else if (testResponse.data.length > 0) {
        // console.log("Good");
        console.log("In else if test result data", testResponse.data);
        setStudents(testResponse.data);
        console.log("data.data", testResponse.data);
        // setShowDeleteButton(true);
      } else {
        setStudents([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _onSubmit = async (e) => {
    e.preventDefault();

    console.log("students", students);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/test-results/post-test-results",
        { results: students }
      );
      console.log("response after post", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackOpen(true);
        if (accesLevel === "SCHOOL") {
          const accessObject = Object.assign({}, access);
          const schoolID = accessObject[0].SchoolId;
          console.log("school id", schoolID);
          setSchoolIdentity(schoolID);
          if (Object.keys(locationState.selectedTest).length > 0) {
            console.log("schoolId", schoolID);
            _fetchStudentsByClass(schoolID, ClassID);
          }
        }
        if (accesLevel === "ADMIN") {
          const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
            .SchoolId;
          console.log("school id", schoolID);
          setSchoolIdentity(schoolID);
          if (Object.keys(locationState.selectedTest).length > 0) {
            console.log("schoolId", schoolID);
            _fetchStudentsByClass(schoolID, ClassID);
          }
        }
      }
      console.log("locationState");
      console.log(locationState);
      navigate("/test-management", {
        state: {
          defaultVals: { ...locationState.params },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const _onDelete = async () => {
  //   handleAnchorClose();
  //   setDialogOpen(true);
  // };

  // const _handleDeleteAllTestResults = async () => {
  //   console.log("selectedTestResult", students);
  //   const results = students.map((student) => {
  //     return {
  //       TestID: student.TestID,
  //       SchoolID: student.SchoolID,
  //     };
  //   });
  //   console.log("selectedTestResult", results);
  //   if (results.length > 0) {
  //     const schoolId = results[0].SchoolID;
  //     // const id = Number(id);
  //     console.log("schoolId ===>", schoolId);
  //     console.log("TestID ===>", id);
  //     try {
  //       const { data, status } = await axiosInstance.delete(
  //         `/test-results/delete-test-results-by-school/${schoolId}/${id}`,
  //         { results }
  //       );
  //       if (status === 200) {
  //         setDialogOpen(false);
  //         setSnackOpen(true);
  //         setSnackMessage(data.message);
  //         if (accesLevel === "SCHOOL") {
  //           const accessObject = Object.assign({}, access);
  //           const schoolID = accessObject[0].SchoolId;
  //           console.log("school id", schoolID);
  //           setSchoolIdentity(schoolID);
  //           if (Object.keys(locationState.selectedTest).length > 0) {
  //             console.log("schoolId", schoolID);
  //             _fetchStudentsByClass(schoolID, ClassID);
  //           }
  //         }
  //         if (accesLevel === "ADMIN") {
  //           const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
  //             .SchoolId;
  //           console.log("school id", schoolID);
  //           setSchoolIdentity(schoolID);
  //           if (Object.keys(locationState.selectedTest).length > 0) {
  //             console.log("schoolId", schoolID);
  //             _fetchStudentsByClass(schoolID, ClassID);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const _handleOnChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...students];
    list[index].ObtainedMarks = value <= MaxMarks ? value : "";

    setStudents(list);
  };

  const _handleOnCheckBoxChange = (e, index, student) => {
    const { name, value } = e.target;
    const list = [...students];
    list[index].Present = !list[index].Present;
    list[index].ObtainedMarks = student.Present === false ? 0 : value;

    setStudents(list);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    console.log("event", event);
    setAnchorEl(event.currentTarget);
  };

  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };

  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchStudentData = async () => {
    const accessObject = Object.assign({}, access);
    const schoolID = accessObject[0].SchoolId;
    try {
      const result = await axiosInstance.get(
        `/students/get-student-by-id/${GRNO}/${schoolID}`
      );
      setMissingStudentData({
        studentId: result.data.data.SID,
        studentName: result.data.data.StudentName,
        activeSession: result.data.data.ActiveSession,
        className: result.data.data.ClassModel.ClassName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const submitMissingStudentModal = async () => {
    const accessObject = Object.assign({}, access);
    const schoolID = accessObject[0].SchoolId;
    const data = {
      TestID: id,
      SID: missingStudentData.studentId,
      SchoolID: schoolID,
      ObtainedMarks: missingStudentData.obtainedMarks,
      Present: missingStudentData.isPresent,
    };
    try {
      const response = await axiosInstance.post(
        "/test-results/add-missing-student",
        { result: data }
      );
      await _fetchStudentsByClass(schoolID, ClassID);
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteStudent = async (studentId) => {
    const accessObject = Object.assign({}, access);
    const schoolID = accessObject[0].SchoolId;
    try {
      const response = await axiosInstance.delete(
        `/test-results/remove-student/${studentId}/${schoolID}/${id}`
      );
      await _fetchStudentsByClass(schoolID, ClassID);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box flexGrow={1}>
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>
          <Typography variant="h6" component="h1">
            Test Results Module
          </Typography>
        </Stack>
        <Button variant="contained" onClick={() => openModal()}>
          Add Missing Student
        </Button>
        {/* <div>
          <p>Add Missing Student</p>
        </div> */}
        {/* <Box width={300}>
          {showDeleteButton && (
            <FormControl fullWidth>
              <Button variant="contained" color="error" onClick={_onDelete}>
                Delete Test Results
              </Button>
            </FormControl>
          )}
        </Box> */}
      </Stack>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Stack
        sx={{ mb: 2 }}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box onSubmit={_onSubmit} component="form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Is Present</TableCell>
                      <TableCell align="center">Test Date</TableCell>
                      <TableCell align="center">Session</TableCell>
                      <TableCell align="center">GRNO</TableCell>
                      <TableCell align="center">Student Name</TableCell>
                      <TableCell align="center">Subject Name</TableCell>
                      <TableCell align="center">Subject Variation</TableCell>
                      <TableCell align="center">Max Marks</TableCell>
                      <TableCell align="center">Obtained Marks</TableCell>
                      <TableCell align="center">Passing Percentage</TableCell>
                      <TableCell align="right">Percentage</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.length > 0 &&
                      students.map((student, index) => {
                        return (
                          <TableRow
                            key={String(index)}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <FormControlLabel
                                value={student.Present}
                                name="Present"
                                control={<Checkbox checked={student.Present} />}
                                onChange={(e) =>
                                  _handleOnCheckBoxChange(e, index, student)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(TestDate).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell align="center">{Session}</TableCell>
                            <TableCell align="center">
                              {student.GRNO
                                ? student.GRNO
                                : student.Student.GRNO}
                            </TableCell>
                            <TableCell align="center">
                              {student.StudentName
                                ? student.StudentName
                                : student.Student.StudentName}
                            </TableCell>
                            <TableCell align="center">
                              {Subject.SubjectName}
                            </TableCell>
                            <TableCell align="center">
                              {SubjectVariation === "" ||
                              SubjectVariation === null ||
                              SubjectVariation === undefined
                                ? "None"
                                : SubjectVariation}
                            </TableCell>
                            <TableCell align="center">{MaxMarks}</TableCell>
                            <TableCell align="center">
                              <TextField
                                size="small"
                                variant="outlined"
                                sx={{ display: "flex", flex: 1 }}
                                type="number"
                                label="Obtained Marks"
                                name="ObtainedMarks"
                                // disabled={student.Present}
                                value={student.ObtainedMarks}
                                required
                                onChange={(e) =>
                                  _handleOnChange(e, index, student)
                                }
                              />
                            </TableCell>
                            <TableCell align="center">{`${PassingPercentage}%`}</TableCell>
                            <TableCell align="right">{`${
                              student.ObtainedMarks
                                ? (
                                    (student.ObtainedMarks * 100) /
                                    MaxMarks
                                  ).toFixed(2)
                                : 0
                            }%`}</TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setDeletedStudentId(parseInt(student.SID));
                                  setIsDeleteModalOpen(true);
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                SAVE CHANGES
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openAnchor}
          onClose={handleAnchorClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            //   onClick={_onDelete}
            color="error"
          >
            Delete
          </MenuItem>
          <MenuItem
          //   onClick={handleEditOpen}
          >
            Edit
          </MenuItem>
        </Menu>
      </Stack>
      {isDeleteModalOpen && (
        <Dialog fullWidth maxWidth="sm" open={isDeleteModalOpen}>
          <DialogTitle>Delete Student</DialogTitle>
          <DialogContent>
            <Box flexGrow={1}>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="body1">
                Are you sure you want to delete this student with ID {deletedStudentId}?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {setIsDeleteModalOpen(false)}}>Cancel</Button>
            <LoadingButton
              loading={loading}
              disabled={loading}
              variant="contained"
              color="error"
              onClick={() => {
                deleteStudent(deletedStudentId)
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}

      {isModalOpen && (
        <Dialog fullWidth maxWidth="sm" open={isModalOpen}>
          <DialogTitle>Add Missing Student</DialogTitle>
          <DialogContent>
            <Box flexGrow={1}>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{ mb: 2 }}
                  alignItems={"center"}
                >
                  <Grid item xs={12} md={9}>
                    <TextField
                      id="grno"
                      label="GRNO"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="grno"
                      value={GRNO === 0 ? "" : GRNO}
                      required
                      onChange={(e) => {
                        setGRNO(parseInt(e.target.value));
                      }}
                      size="small"
                      inputProps={{
                        type: "number",
                        min: 1,
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        fetchStudentData();
                      }}
                    >
                      Get
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="student-name"
                      label="Student Name"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="StudentName"
                      size="small"
                      value={missingStudentData.studentName}
                      disabled={true}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="class-name"
                      label="Class"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="Class"
                      size="small"
                      value={missingStudentData.className}
                      disabled={true}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="active-session"
                      label="Active Session"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="ActiveSession"
                      size="small"
                      value={missingStudentData.activeSession}
                      disabled={true}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="obtained-marks"
                      label="Obtained Marks"
                      sx={{ display: "flex", flex: 1 }}
                      variant="outlined"
                      name="ObtainedMarks"
                      onChange={(e) =>
                        setMissingStudentData((prev) => ({
                          ...prev,
                          obtainedMarks: e.target.value,
                        }))
                      }
                      size="small"
                      value={
                        missingStudentData?.obtainedMarks === 0
                          ? ""
                          : missingStudentData.obtainedMarks
                      }
                      inputProps={{
                        type: "number",
                        min: 0,
                      }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} md={6} alignItems={"center"}>
                    <Checkbox
                      label="Present"
                      defaultChecked
                      value={missingStudentData.isPresent}
                      onChange={(e) =>
                        setMissingStudentData((prev) => ({
                          ...prev,
                          isPresent: e.target.checked,
                        }))
                      }
                    />
                    <label>Present</label>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoadingButton
                      loading={loading}
                      disabled={loading}
                      // onClick={_editSyllabus}
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        submitMissingStudentModal();
                      }}
                    >
                      SAVE
                    </LoadingButton>
                  </Grid>
                </Grid>
                <Grid container spacing={2}></Grid>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeModal()}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Create Test Results
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={() => {}} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                <Typography variant="span" color="error" component="h4">
                  Note: Please Select Class first before Subject.
                </Typography>
              </Grid>*/}
              <Grid item xs={12} md={12}>
                <SectionDivider
                  text={`Date: ${dayjs(TestDate).format(
                    "YYYY-MM-DD"
                  )} Subject: ${Subject.SubjectName}`}
                />
              </Grid>
              <Grid>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Is Present</TableCell>
                        <TableCell align="center">Test Date</TableCell>
                        <TableCell align="center">GRNO</TableCell>
                        <TableCell align="center">Student Name</TableCell>
                        <TableCell align="center">Subject Name</TableCell>
                        <TableCell align="center">Max Marks</TableCell>
                        <TableCell align="center">Obtain Marks</TableCell>
                        <TableCell align="center">Passing Percentage</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {students.length > 0 &&
                        students.map((student, index) => {
                          return (
                            <TableRow
                              key={String(index)}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <FormControlLabel
                                  // value={menu.create}
                                  name="IsPresent"
                                  control={
                                    <Checkbox
                                    // checked={menu.create}
                                    />
                                  }
                                  // label="CREATE"
                                  // labelPlacement="CREATE"
                                  // onChange={(e) => handleMenusChange(e, index)}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {dayjs(TestDate).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell align="center">
                                {student.GRNO}
                              </TableCell>
                              <TableCell align="center">
                                {student.StudentName}
                              </TableCell>
                              <TableCell align="center">
                                {Subject.SubjectName}
                              </TableCell>
                              <TableCell align="center">{MaxMarks}</TableCell>
                              <TableCell align="center">
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  sx={{ display: "flex", flex: 1 }}
                                  type="number"
                                  label="Obtained Marks"
                                  name="ObtainedMarks"
                                  required
                                  //   value={school}
                                />
                              </TableCell>
                              <TableCell align="right">{`${PassingPercentage}%`}</TableCell>
                              <TableCell align="right">{`${
                                obtainedPercentage !== null
                                  ? obtainedPercentage
                                  : ""
                              }%`}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Post Results
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to delete all test results.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="error">
            Are you sure you want to Delete all Test Results?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No, Cancel</Button>
          <Button color="error" onClick={_handleDeleteAllTestResults} autoFocus>
            Yes, Delete Test
          </Button>
        </DialogActions>
      </Dialog> */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
