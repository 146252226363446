import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import useSession from "../../CustomHooks/useSession";
import { getClusters } from "../Clusters/Clusters";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Cap() {
  const navigate = useNavigate();
  const [snackbar, setSnackBar] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteSnackbar, setDeleteSnackBar] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [responsibles, setResponsibles] = useState([]);
  const [designation, setDesignation] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [capData, setCapData] = useState([]);
  const [selectedCapData, setSelectedCapData] = useState({});
  const [teacherAttendanceData, setTeacherAttendanceData] = useState([]);
  const [selectedTeacherAttendance, setSelectedTeacherAttendance] = useState(
    []
  );
  const [selectedParentAttendance, setSelectedParentAttendance] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [selectedResponsible, setSelectedResponsible] = useState(null);
  const [selectedInputResponsible, setSelectedInputResponsible] = useState("");
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openTeacherAttendance, setOpenTeacherAttendance] = useState(false);
  const [openParentAttendance, setOpenParentAttendance] = useState(false);
  const [formData, setFormData] = useState({
    CapDate: dayjs().format("YYYY-MM-DD"),
    Topic: "",
    AddressBy: "",
    ResponsibleName: "",
    ResponsibleDesignation: "",
    Narration: "",
    Programme: "",
    OtherParentCount: "",
    Alumni: "",
  });
  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      const schoolId = accessObject[0].SchoolId;
      const searchParams = new URLSearchParams();
      searchParams.append("school_id", schoolId);
      setSchoolId(schoolId);
    }
    _fetchCapData();

    _fetchResponsible();
    _fetchAttendance();
    _fetchStudentsData();
  }, []);
  const _fetchCapData = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const { data, status } = await axiosInstance.get(
          `/cap-attendance/${schoolId}`
        );
        console.log("setCapData", data.data);
        if (status === 200) {
          setCapData(data.data);
        }
      }
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        const { data, status } = await axiosInstance.get(
          `/cap-attendance/${schoolID}`
        );
        console.log("setCapData", data.data);
        if (status === 200) {
          setCapData(data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchResponsible = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        `/teachers/by-school-designation`
      );
      console.log("responsibles", data.data);
      if (status === 200) {
        setResponsibles(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchAttendance = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        searchParams.append("date", dayjs().format("YYYY-MM-DD"));
        const { status, data } = await axiosInstance.get(
          `/staff-attendance/current-date`,
          {
            params: searchParams,
          }
        );
        setTeacherAttendanceData(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _fetchStudentsData = async () => {
    try {
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolId = accessObject[0].SchoolId;
        const searchParams = new URLSearchParams();
        searchParams.append("school_id", schoolId);
        const { status, data } = await axiosInstance.get(
          `/students/by-school/${schoolId}`
        );
        console.log("getstudents", data.data);
        const list = data.data.map((student) => {
          return {
            SID: student.SID,
            StudentName: student.StudentName,
            FathersName: student.FathersName,
            MothersName: student.MothersName,
            GuardianName: student.GuardianName,
            ClassName: student.ClassModel ? student.ClassModel.ClassName : "",
            isPresent: true,
          };
        });
        console.log("get students list", list);
        setStudentData(list);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    const capData = {
      TeacherAttendanceJSON: teacherAttendanceData,
      ParentAttendanceJSON: studentData,
      schoolid: schoolId,
      CapDate: formData.CapDate,
      Topic: formData.Topic,
      AddressBy: formData.AddressBy,
      ResponsibleName: formData.ResponsibleName,
      ResponsibleDesignation: formData.ResponsibleDesignation,
      Narration: formData.Narration,
      Programme: formData.Programme,
      OtherParentCount: formData.OtherParentCount,
      Alumni: formData.Alumni,
    };

    console.log("CAP Data", capData);

    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post(
        "/cap-attendance",
        capData
      );
      console.log("response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchCapData();
        handleAnchorClose();
        handleClose();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();

    const editCapData = {
      CapDate: selectedCapData.CapDate,
      Topic: selectedCapData.Topic,
      AddressBy: selectedCapData.AddressBy,
      ParentAttendanceJSON: selectedParentAttendance,
      TeacherAttendanceJSON: selectedTeacherAttendance,
      ResponsibleName: selectedCapData.ResponsibleName,
      ResponsibleDesignation: selectedCapData.ResponsibleDesignation,
      Narration: selectedCapData.Narration,
      Programme: selectedCapData.Programme,
      OtherParentCount: selectedCapData.OtherParentCount,
      Alumni: selectedCapData.Alumni,
    };

    console.log("EDIT DATA", editCapData);

    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/cap-attendance/${selectedCapData.CAPID}`,
        editCapData
      );
      console.log("response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchCapData();
        handleAnchorClose();
        handleEditClose();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onDelete = async (e) => {
    e.preventDefault();

    try {
      const { data, status } = await axiosInstance.delete(
        `/cap-attendance/${selectedCapData.CAPID}`
      );
      console.log("response", data.data);
      if (status === 200) {
        setSnackMessage(data.message);
        setSnackBar(true);
        _fetchCapData();
        handleAnchorClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = (id) => (event) => {
    setTeacherAttendanceData((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            Present: !item.Present,
          };
        }
        return item;
      });
    });
  };
  const handleEditToggle = (id) => (event) => {
    setSelectedTeacherAttendance((prevState) => {
      return prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            Present: !item.Present,
          };
        }
        return item;
      });
    });
  };
  const handleStudentToggle = (id) => (event) => {
    setStudentData((prevState) => {
      return prevState.map((item) => {
        if (item.SID === id) {
          return {
            ...item,
            isPresent: !item.isPresent,
          };
        }
        return item;
      });
    });
  };
  const handleStudentEditToggle = (id) => (event) => {
    setSelectedParentAttendance((prevState) => {
      return prevState.map((item) => {
        if (item.SID === id) {
          return {
            ...item,
            isPresent: !item.isPresent,
          };
        }
        return item;
      });
    });
  };
  const _handleCAPDate = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        CapDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleEditCAPDate = (e) => {
    setSelectedCapData((prevState) => {
      return {
        ...prevState,
        CapDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleOnChange = (e) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const _handleOnEditChange = (e) => {
    setSelectedCapData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _onResponsibleChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedResponsible(newValue);
    setDesignation(newValue.TeachersDesignation);
    setFormData((prevState) => {
      return {
        ...prevState,
        ResponsibleID: newValue.id,
      };
    });
  };
  const _onEditResponsibleChange = (event, newValue) => {
    console.log("On Observer Change", newValue);
    setSelectedResponsible(newValue);
    setDesignation(newValue.TeachersDesignation);
    setSelectedCapData((prevState) => {
      return {
        ...prevState,
        ResponsibleID: newValue.id,
      };
    });
  };
  const _onResponsibleInputValue = (event, newInputValue) => {
    setSelectedInputResponsible(newInputValue);
  };
  const handleTeacherAttendanceOpen = (item) => () => {
    setOpenTeacherAttendance(true);
    setSelectedCapData(item);
    const teacherAttendance = JSON.parse(item.TeacherAttendanceJSON);
    setSelectedTeacherAttendance(teacherAttendance);
  };
  const handleTeacherAttendanceClose = () => {
    setOpenTeacherAttendance(false);
  };
  const handleParentAttendanceOpen = (item) => () => {
    setOpenParentAttendance(true);
    setSelectedCapData(item);
    const parentAttendance = JSON.parse(item.ParentAttendanceJSON);
    console.log("parentAttendance", parentAttendance);

    setSelectedParentAttendance(parentAttendance);
  };
  const handleParentAttendanceClose = () => {
    setOpenParentAttendance(false);
  };
  const handleClick = (item) => (event) => {
    console.log("Selected CapData", item);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedCapData(item);
    const teacherAttendance = JSON.parse(item.TeacherAttendanceJSON);
    setSelectedTeacherAttendance(teacherAttendance);
    const parentAttendance = JSON.parse(item.ParentAttendanceJSON);
    console.log("parentAttendance", parentAttendance);

    setSelectedParentAttendance(parentAttendance);
  };
  const _handleResponsibleData = () => {
    setSelectedResponsible({
      TeachersDesignation:
        Object.keys(selectedCapData).length > 0 &&
        selectedCapData.Teacher.Designation.TeachersDesignation,
      label: `(${
        Object.keys(selectedCapData.Teacher).length > 0 &&
        selectedCapData.Teacher.SchoolTeachersQId
      }) - ${
        Object.keys(selectedCapData).length > 0 &&
        selectedCapData.Teacher.TeacherName
      } - ${
        selectedCapData.Teacher.School !== null &&
        selectedCapData.Teacher.School.NameOfSchool
      }`,
      id:
        Object.keys(selectedCapData).length > 0 &&
        selectedCapData.Teacher.SchoolTeachersQId,
    });
    setDesignation(selectedCapData.Teacher.Designation.TeachersDesignation);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEditOpen = () => {
    // _handleResponsibleData();
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };
  const classOrder = [
    "KG I",
    "KG II",
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
  ];

  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Community Module
            </Typography>
          </Stack>
          <Box width={300}>
            {currentPageCRUD.create && (
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  Add CAP
                </Button>
              </FormControl>
            )}
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="center">Topic</TableCell>
                  <TableCell align="center">Conductor Name</TableCell>
                  <TableCell align="center">Responsible Name</TableCell>
                  <TableCell align="center">Responsible Designation</TableCell>
                  <TableCell align="center">Teacher Attendance</TableCell>
                  <TableCell align="center">Parent Attendance</TableCell>
                  <TableCell align="center">Narration</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageCRUD.read &&
                  capData.map((item, index) => {
                    return (
                      <TableRow
                        key={String(index)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {dayjs(item.CapDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="center">{item.Topic}</TableCell>
                        <TableCell align="center">{item.AddressBy}</TableCell>
                        <TableCell align="center">
                          {item.ResponsibleName}
                        </TableCell>
                        <TableCell align="center">
                          {item.ResponsibleDesignation}
                        </TableCell>
                        <TableCell align="center">
                          <Button onClick={handleTeacherAttendanceOpen(item)}>
                            attendance
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button onClick={handleParentAttendanceOpen(item)}>
                            attendance
                          </Button>
                        </TableCell>
                        <TableCell align="center">{item.Narration}</TableCell>
                        <TableCell align="right">
                          <IconButton onClick={handleClick(item)}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {currentPageCRUD.update && (
                <MenuItem onClick={handleEditOpen}>Edit</MenuItem>
              )}
              {currentPageCRUD.delete && (
                <MenuItem onClick={_onDelete}>Delete</MenuItem>
              )}
            </Menu>
          </TableContainer>
          <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>Create CAP</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onSubmit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date"
                          value={formData.CapDate}
                          name="CapDate"
                          onChange={_handleCAPDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Topic"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        name="Topic"
                        onChange={_handleOnChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/*<Autocomplete
                        disablePortal
                        id="combo-box-teacher"
                        options={responsibles.map((item, index) => {
                          return {
                            // ...item,
                            TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                            label: `(${item.SchoolTeachersQId}) - ${
                              item.TeacherName
                            } - ${
                              item.School !== null && item.School.NameOfSchool
                            }`,
                            id: item.SchoolTeachersQId,
                          };
                        })}
                        value={selectedResponsible}
                        onChange={_onResponsibleChange}
                        inputValue={selectedInputResponsible}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        onInputChange={_onResponsibleInputValue}
                        sx={{ display: "flex", flex: 1 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            key={params.id}
                            label="Responsible Name"
                            size="small"
                          />
                        )}
                      />*/}
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Responsible Name"
                        name="ResponsibleName"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Responsible Designation"
                        name="ResponsibleDesignation"
                      >
                        <MenuItem value="Principal">Principal</MenuItem>
                        <MenuItem value="CI">CI</MenuItem>
                        <MenuItem value="Teacher">Teacher</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Conductor Name"
                        name="AddressBy"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Select Programme"
                        name="Programme"
                      >
                        <MenuItem value="Parent">Parent Programme</MenuItem>
                        <MenuItem value="Teacher">Teacher Programme</MenuItem>
                        <MenuItem value="Alumni">Alumni Programme</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        onChange={_handleOnChange}
                        label="Narration"
                        name="Narration"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Teacher Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Teachers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            {teacherAttendanceData.map((teacher, index) => {
                              return (
                                <Grid item xs={12} md={4}>
                                  <Box>
                                    <List sx={{ width: "100%" }}>
                                      <ListItem key={String(index)}>
                                        <ListItemText
                                          id="switch-list-label-wifi"
                                          primary={teacher.Teacher.TeacherName}
                                        />
                                        <Switch
                                          edge="end"
                                          onChange={handleToggle(teacher.id)}
                                          checked={teacher.Present}
                                          inputProps={{
                                            "aria-labelledby":
                                              "switch-list-label-wifi",
                                          }}
                                        />
                                      </ListItem>
                                    </List>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Parent Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">
                            Mothers Name / Father Name
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} md={4} sx={{ marginBottom: 2 }}>
                            <TextField
                              label="Other Parents Strength"
                              variant="outlined"
                              size="small"
                              sx={{ display: "flex", flex: 1 }}
                              name="OtherParentCount"
                              onChange={_handleOnChange}
                            />
                          </Grid>

                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Class Name</TableCell>
                                  <TableCell align="center">
                                    Mother Name
                                  </TableCell>
                                  <TableCell align="right">Present</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {studentData
                                  .sort(
                                    (a, b) =>
                                      classOrder.indexOf(a.ClassName) -
                                      classOrder.indexOf(b.ClassName)
                                  )
                                  .map((student, index) => {
                                    return (
                                      <TableRow
                                        key={String(index)}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          component="th"
                                          scope="row"
                                        >
                                          {student.ClassName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {`${student.StudentName} (${
                                            student.MothersName == undefined
                                              ? ""
                                              : `${student.MothersName}/`
                                          }${student.FathersName})`}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          sx={{ width: "30%" }}
                                        >
                                          <Switch
                                            edge="end"
                                            onChange={handleStudentToggle(
                                              student.SID
                                            )}
                                            checked={student.isPresent}
                                            inputProps={{
                                              "aria-labelledby":
                                                "switch-list-label-wifi",
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Alumni Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Alumni</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} md={6}>
                            <Box>
                              <TextField
                                label="Total Alumni Strength"
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                name="Alumni"
                                onChange={_handleOnChange}
                              />
                            </Box>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus}
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Create CAP
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openEdit}
            onClose={handleEditClose}
          >
            <DialogTitle>Edit CAP</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box onSubmit={_onEdit} component="form">
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          inputFormat="DD-MM-YYYY"
                          label="Date"
                          value={selectedCapData.CapDate}
                          name="CapDate"
                          onChange={_handleEditCAPDate}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "flex", flex: 1 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Topic"
                        variant="outlined"
                        size="small"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCapData.Topic}
                        name="Topic"
                        onChange={_handleOnEditChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/*<Autocomplete
                        disablePortal
                        id="combo-box-teacher"
                        options={responsibles.map((item, index) => {
                          return {
                            // ...item,
                            TeachersDesignation: `${item.Designation.TeachersDesignation}`,
                            label: `(${item.SchoolTeachersQId}) - ${
                              item.TeacherName
                            } - ${
                              item.School !== null && item.School.NameOfSchool
                            }`,
                            id: item.SchoolTeachersQId,
                          };
                        })}
                        value={selectedResponsible}
                        onChange={_onEditResponsibleChange}
                        inputValue={selectedInputResponsible}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        onInputChange={_onResponsibleInputValue}
                        sx={{ display: "flex", flex: 1 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            key={params.id}
                            label="Responsible Name"
                            size="small"
                          />
                        )}
                      />*/}
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCapData.ResponsibleName}
                        onChange={_handleOnEditChange}
                        label="Responsible Name"
                        name="ResponsibleName"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCapData.ResponsibleDesignation}
                        onChange={_handleOnEditChange}
                        label="Responsible Designation"
                        name="ResponsibleDesignation"
                      >
                        <MenuItem value="Principal">Principal</MenuItem>
                        <MenuItem value="CI">CI</MenuItem>
                        <MenuItem value="Teacher">Teacher</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCapData.AddressBy}
                        onChange={_handleOnEditChange}
                        label="Conductor Name"
                        name="AddressBy"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        id="standard-select-currency"
                        select
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCapData.Programme}
                        onChange={_handleOnEditChange}
                        label="Select Programme"
                        name="Programme"
                      >
                        <MenuItem value="Parent">Parent Programme</MenuItem>
                        <MenuItem value="Teacher">Teacher Programme</MenuItem>
                        <MenuItem value="Alumni">Alumni Programme</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        size="small"
                        variant="outlined"
                        sx={{ display: "flex", flex: 1 }}
                        value={selectedCapData.Narration}
                        onChange={_handleOnEditChange}
                        label="Narration"
                        name="Narration"
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Teacher Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Teachers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            {selectedTeacherAttendance.map((teacher, index) => {
                              return (
                                <Grid item xs={12} md={4}>
                                  <Box>
                                    <List sx={{ width: "100%" }}>
                                      <ListItem key={String(index)}>
                                        <ListItemText
                                          id="switch-list-label-wifi"
                                          primary={teacher.Teacher.TeacherName}
                                        />
                                        <Switch
                                          edge="end"
                                          onChange={handleEditToggle(
                                            teacher.id
                                          )}
                                          checked={teacher.Present}
                                          inputProps={{
                                            "aria-labelledby":
                                              "switch-list-label-wifi",
                                          }}
                                        />
                                      </ListItem>
                                    </List>
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Parent Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">
                            Mothers Name / Father Name
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid item xs={12} md={4} sx ={{marginBottom: 2}}>
                        <TextField
                          label="Other Parents Strength"
                          variant="outlined"
                          size="small"
                          sx={{ display: "flex", flex: 1 }}
                          name="OtherParentCount"
                          value={selectedCapData.OtherParentCount}
                          onChange={_handleOnEditChange}
                        />
                      </Grid>

                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      Class Name
                                    </TableCell>
                                    <TableCell align="center">Mother Name</TableCell>
                                    <TableCell align="right">
                                      Present
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedParentAttendance
                                  .sort((a, b) => classOrder.indexOf(a.ClassName) - classOrder.indexOf(b.ClassName))
                                  .map((student, index) => 
                                  {
                                    return (
                                      <TableRow
                                        key={String(index)}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell align = "left" component="th" scope="row">
                                          {student.ClassName}
                                        </TableCell>
                                        <TableCell align="center">
                                        {`${student.StudentName} (${
                                            student.MothersName == undefined
                                              ? ""
                                              : `${student.MothersName}/`
                                          }${student.FathersName})`}
                                                                        
                                    </TableCell>
                                        <TableCell
                                          align="right"
                                          sx={{ width: "30%" }}
                                        >
                                        <Switch
                                          edge="end"
                                          onChange={handleStudentEditToggle(
                                            student.SID
                                          )}
                                          checked={student.isPresent}
                                          inputProps={{
                                            "aria-labelledby":
                                              "switch-list-label-wifi",
                                          }}
                                        />   
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Divider sx={{ mt: 1, mb: 2 }} />
                      <Typography variant="subtitle2" component="span">
                        Alumni Attendance
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="body2">Alumni</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid item xs={12} md={6}>
                            <Box>
                              <TextField
                                label="Total Alumni Strength"
                                variant="outlined"
                                size="small"
                                sx={{ display: "flex", flex: 1 }}
                                name="Alumni"
                                onChange={_handleOnEditChange}
                                value={selectedCapData.Alumni}
                              />
                            </Box>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LoadingButton
                        loading={loading}
                        disabled={loading}
                        type="submit"
                        // onClick={_editSyllabus
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        Update CAP
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openTeacherAttendance}
            onClose={handleTeacherAttendanceClose}
          >
            <DialogTitle>Teacher Attendance</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Teacher Attendance
                              </TableCell>
                              <TableCell align="right">Teacher Name</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedTeacherAttendance.length > 0 &&
                              selectedTeacherAttendance.map(
                                (attendance, index) => {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {attendance.Present
                                          ? "Present"
                                          : "Absent"}
                                      </TableCell>
                                      <TableCell align="right">
                                        {attendance.Teacher.TeacherName}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleTeacherAttendanceClose}>Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="md"
            open={openParentAttendance}
            onClose={handleParentAttendanceClose}
          >
            <DialogTitle>Parent Attendance</DialogTitle>
            <DialogContent>
              <Box flexGrow={1}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">
                                Parent Attendance
                              </TableCell>
                              <TableCell align="center">Student Name</TableCell>
                              <TableCell align="right">
                                Mothers Name / Father Name
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedParentAttendance.length > 0 &&
                              selectedParentAttendance.map(
                                (attendance, index) => {
                                  return (
                                    <TableRow
                                      key={String(index)}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {attendance.isPresent
                                          ? "Present"
                                          : "Absent"}
                                      </TableCell>
                                      <TableCell align="center">
                                        {attendance.StudentName}
                                      </TableCell>
                                      <TableCell align="right">
                                        {attendance.MothersName == undefined
                                          ? ""
                                          : `${attendance.MothersName} / `}
                                        {attendance.FathersName}
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}></Grid>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleParentAttendanceClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
