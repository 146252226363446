import React, { useEffect, useState } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { Button, Modal, Stack, Menu } from "@mui/material";
import { TextField, Box, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useSession from "../../CustomHooks/useSession";
import axiosInstance from "../../Api/axios.instance";
import { getClasses } from "../ClassSubjects/Classes";
import { getSubjects } from "../ClassSubjects/Subjects";
import { fetchTests } from "../Test";
import ClearIcon from "@mui/icons-material/Clear";
import MUIDataTable from "mui-datatables";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { deDE } from "@mui/x-date-pickers/locales";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const subjectSet = new Set([]);
const SubjectVariationSet = new Set([]);

export default function TestListNew({ tests, fetchTests, defaultVals }) {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [dangerSnack, setDangerSnack] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testList, setTestList] = useState([]);
  const [mappedTestList, setMappedTestList] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [variations, setVariations] = useState([]);
  const { accesLevel, access, currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [selectedTest, setSelectedTest] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState("ALL");
  const [selectedTestType, setSelectedTestType] = useState("ALL");
  const [selectedSession, setSelectedSession] = useState("ALL");
  const [selectedClass, setSelectedClass] = useState("ALL");
  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [subjectVariationArray, setSubjectVariationArray] = useState([]);
  const [defaultCall, setDefaultCall] = useState(false);
  const openAnchor = Boolean(anchorEl);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (locationState !== null) {
      setSelectedTestType(locationState.defaultVals.selectedTestType);
      setSelectedSubject(locationState.defaultVals.selectedSubject);
      setSelectedSession(locationState.defaultVals.selectedSession);
      setSelectedDate(locationState.defaultVals.selectedDate);
      setSelectedClass(locationState.defaultVals.selectedClass);
      setDefaultCall(true);
    }
  }, [locationState]);

  useEffect(() => {
    if (defaultCall) {
      filterTestLists();
    }
  }, [defaultCall]);

  useEffect(() => {
    tests.forEach((x) => {
      subjectSet.add(
        JSON.stringify({ Id: x.Subject.ID, Name: x.Subject.SubjectName })
      );

      SubjectVariationSet.add(x.SubjectVariation);
    });
    const formattedSet = [...subjectSet].map((item) => {
      if (typeof item === "string") return JSON.parse(item);
      else if (typeof item === "object") return item;
    });
    let array = Array.from(formattedSet);
    setSubjects(array);
    setSubjectVariationArray(Array.from(SubjectVariationSet));
    setTestList(tests);
    _fetchClasses();
    setMappedTestList(tests);
  }, []);

  const handleModalClose = () => setOpenModal(false);
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _fetchClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const _fetchSubjectVariationVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-subject-variations"
      );
      if (data.data.length > 0) {
        setVariations(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const _fetchTests = async () => {
    try {
      const res = await fetchTests();
      const activeTest = res.data.filter((test) => test.SchoolID === null);
      setTestList(activeTest);
    } catch (error) {
      console.error(error);
    }
  };
  const _onDelete = async () => {
    handleAnchorClose();
    if (accesLevel === "SCHOOL") {
      setOpenError(true);
      return;
    }
    if (accesLevel === "ADMIN") {
      const { data: testResponse, status } = await axiosInstance.delete(
        `/tests/${selectedTest.id}`
      );
      if (status === 200) {
        setDangerSnack(true);
        setSnackMessage(testResponse.message);
        fetchTests();
      } else {
        setDialogOpen(true);
      }
    }
  };
  const _handleDeleteTest = async () => {
    try {
      const { data, status } = await axiosInstance.delete(
        `/tests/${selectedTest.id}`
      );

      if (status === 200) {
        setDialogOpen(false);
        setOpen(true);
        setSnackMessage(data.message);
        _fetchTests();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const _onEdit = async (e) => {
    e.preventDefault();
    handleAnchorClose();
    const currentDateTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const currentUserID = JSON.parse(localStorage.getItem("User")).id;

    const testEditData = {
      TestDate: selectedTest.TestDate,
      Session: selectedTest.Session,
      Testype: selectedTest.Testype,
      Status: selectedTest.Status,
      MaxMarks: selectedTest.MaxMarks,
      PassingPercentage: selectedTest.PassingPercentage,
      ClassID: selectedTest.ClassID,
      SubjectID: selectedTest.SubjectID,
      SubjectVariation: selectedTest.SubjectVariation,
      SchoolID: null,
      CreatedDateTime: selectedTest.CreatedDateTime,
      UserID: selectedTest.UserID,
      ModificationDateTime: currentDateTime,
      ModificationByUser: currentUserID,
    };
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/tests/${selectedTest.id}`,
        testEditData
      );

      if (status === 200) {
        setLoading((prevState) => !prevState);
        setOpenModal(false);
        setOpen(true);
        setSnackMessage(data.message);
        _fetchTests();
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.error(error);
    }
  };

  const _handleEditClassChange = async (e) => {
    setSelectedTest((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${e.target.value}`
      );
      if (status === 200) {
        // setSubjects(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const _handleEditTestDate = (e) => {
    setSelectedTest((prevState) => {
      return {
        ...prevState,
        TestDate: dayjs(e).format("YYYY-MM-DD"),
      };
    });
  };
  const _handleOnEditChange = (e) => {
    setSelectedTest((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleClick = (item) => async (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedTest(item);
    try {
      const { data, status } = await axiosInstance.get(
        `/class-subjects/by-class/${item.ClassID}`
      );
      if (status === 200) {
        // setSubjects(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const snack_handleDangerClose = () => {
    setDangerSnack(false);
  };
  const snack_handleErrorClose = () => {
    setOpenError(false);
  };
  const snack_handleClose = () => {
    setOpen(false);
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const requestSearch = () => {
    const filteredTests = tests?.filter((test) => {
      return (
        dayjs(test?.TestDate)
          .format("YYYY-MM-DD")
          .toLowerCase()
          .includes(searchValue?.toLowerCase()) ||
        test?.Session.toLowerCase().includes(searchValue?.toLowerCase())
        // Add other fields to be searched as needed...
      );
    });
    setTestList(filteredTests);
  };

  const cancelSearch = () => {
    setSearchValue("");
    setTestList(tests);
  };

  useEffect(() => {
    requestSearch();
  }, [searchValue]);

  const handleEdit = async (rowData) => {
    setSelectedTest(rowData);
    setOpenModal(true);
  };

  const handleDelete = async (rowData) => {
    setSelectedTest(rowData);
    setDialogOpen(true);
  };

  const handleTestResult = (data) => {
    navigate("results", {
      state: {
        selectedTest: data,
        params: {
          selectedSubject: selectedSubject,
          selectedTestType: selectedTestType,
          selectedSession: selectedSession,
          selectedDate: selectedDate,
          selectedClass: selectedClass,
        },
      },
    });
  };

  const columns = [
    { label: "Test Date", name: "TestDate" },
    { label: "Session", name: "Session" },
    { label: "Test Type", name: "Testype" },
    { label: "Max Marks", name: "MaxMarks" },
    { label: "Passing Percentage", name: "PassingPercentage" },
    {
      label: "Subject",
      name: "SubjectName",
      options: {
        customBodyRender: (value) => value,
      },
    },
    { label: "Subject Variation", name: "SubjectVariation" },
    {
      label: "Class",
      name: "ClassName",
      options: {
        customBodyRender: (value) => value,
      },
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
    handleClick();
    //setSelectedItem(null);
    //setAction("Add");
  };

  const handleClose = () => {
    setOpen(false);
  };

  function filterTestLists() {
    if (
      selectedSubject === "ALL" &&
      selectedTestType === "ALL" &&
      selectedSession === "ALL" &&
      selectedClass === "ALL" &&
      selectedDate === "ALL"
    ) {
      setMappedTestList(testList);
      testList.forEach((x) => {
        SubjectVariationSet.add(x.SubjectVariation);
      });
      setSubjectVariationArray(Array.from(SubjectVariationSet));
    } else {
      console.log(
        testList,
        selectedSubject,
        selectedTestType,
        selectedSession,
        selectedClass,
        selectedDate,
      );
      let data = [...testList];
      if (selectedSubject !== "ALL") {
        data = data.filter((x) => {
          return x.Subject.SubjectName === selectedSubject;
        });
      }
      if (selectedTestType !== "ALL") {
        data = data.filter((x) => {
          return x.Testype === selectedTestType;
        });
      }
      if (selectedSession !== "ALL") {
        data = data.filter((x) => {
          return x.Session === selectedSession;
        });
      }
      if (selectedClass !== "ALL") {
        data = data.filter((x) => {
          return x.ClassModel.ClassName === selectedClass;
        });
      }
      if (selectedDate !== "ALL") {
        let d = new Date(selectedDate["$d"]);
        let dd = formatWithoutTimeZoneOffset(d);
        data = data.filter((x) => {
          return x.TestDate.split("T")[0] === dd;
        });
      }
      SubjectVariationSet.clear();
      data.forEach((x) => {
        SubjectVariationSet.add(x.SubjectVariation);
      });
      console.log(data);
      setSubjectVariationArray(Array.from(SubjectVariationSet));
      setMappedTestList(data);
    }
    handleClose();
  }

  function formatWithoutTimeZoneOffset(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const order = [
      "KG I",
      "KG II",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX",
      "X",
    ];
    const sortedArray = classes.sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
    setClasses(sortedArray);
  }, [classes]);

  return (
    <>
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Test List Module
            </Typography>
            <Typography variant="subtitle2" component="span">
              Click Get Test List button to get test list
            </Typography>
          </Stack>
          {currentPageCRUD.create && (
            <Button variant="contained" onClick={handleClickOpen}>
              Get Test List
            </Button>
          )}
        </Stack>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle textAlign="center" variant="h6">
          Test List
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <DialogContentText variant="subtitle2" color="secondary">
                  Select Subject & Test Type.
                </DialogContentText>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Subject"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="Subjects"
                  value={selectedSubject}
                  required
                  onChange={(e) => {
                    setSelectedSubject(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  {subjects.map((subject, index) => (
                    <MenuItem value={subject.Name} key={String(index)}>
                      {subject.Name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Test Type"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="TestType"
                  value={selectedTestType}
                  required
                  onChange={(e) => {
                    setSelectedTestType(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  <MenuItem value="WEEKLY">WEEKLY</MenuItem>
                  <MenuItem value="MONTHLY">MONTHLY</MenuItem>
                  <MenuItem value="MID-TERM">MID-TERM</MenuItem>
                  <MenuItem value="ANNUAL">ANNUAL</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Session"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="Session"
                  value={selectedSession}
                  required
                  onChange={(e) => {
                    setSelectedSession(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  <MenuItem value="2015-2016">2015-2016</MenuItem>
                  <MenuItem value="2016-2017">2016-2017</MenuItem>
                  <MenuItem value="2017-2018">2017-2018</MenuItem>
                  <MenuItem value="2018-2019">2018-2019</MenuItem>
                  <MenuItem value="2019-2020">2019-2020</MenuItem>
                  <MenuItem value="2020-2021">2020-2021</MenuItem>
                  <MenuItem value="2021-2022">2021-2022</MenuItem>
                  <MenuItem value="2022-2023">2022-2023</MenuItem>
                  <MenuItem value="2023-2024">2023-2024</MenuItem>
                  <MenuItem value="2024-2025">2024-2025</MenuItem>
                  <MenuItem value="2025-2026">2025-2026</MenuItem>
                  <MenuItem value="2026-2027">2026-2027</MenuItem>
                  <MenuItem value="2027-2028">2027-2028</MenuItem>
                  <MenuItem value="2028-2029">2028-2029</MenuItem>
                  <MenuItem value="2029-2030">2029-2030</MenuItem>
                  <MenuItem value="2030-2031">2030-2031</MenuItem>
                  <MenuItem value="2031-2032">2031-2032</MenuItem>
                  <MenuItem value="2032-2033">2032-2033</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Set a new date"
                    value={selectedDate}
                    onChange={(e) => {
                      setSelectedDate(e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1, mr: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Select Class"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  name="Classes"
                  value={selectedClass}
                  required
                  onChange={(e) => {
                    setSelectedClass(e.target.value);
                  }}
                  size="small"
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  {classes.map((item, index) => (
                    <MenuItem value={item.ClassName} key={String(index)}>
                      {item.ClassName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={filterTestLists}
          >
            Fetch
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* <Paper>
        <MUIDataTable
          title="Test List"
          columns={columns}
          data={testList.map((row) => ({
            TestDate: dayjs(row.TestDate).format("YYYY-MM-DD"),
            Session: row.Session,
            Testype: row.Testype,
            MaxMarks: row.MaxMarks,
            PassingPercentage: row.PassingPercentage,
            "Subject.SubjectName": row.Subject ? row.Subject.SubjectName : "",
            SubjectVariation: row.SubjectVariation,
            "ClassModel.ClassName": row.ClassModel
              ? row.ClassModel.ClassName
              : "",
          }))}
          options={
            {
              // Other options for MUI DataTable
            }
          }
          pagination
          selectableRows
          onRowClick={(rowData) => {
            console.log("Clicked Row:", rowData);
          }}
        />
      </Paper> */}

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={handleModalClose}
      >
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Update Test
            </Typography>
            <Typography variant="body1" component="h4">
              Baithak School Network
            </Typography>
            <Typography variant="body1" component="h4">
              Society for educational walfare registered
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onEdit} component="form">
            <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
              {/*<Grid item xs={12} md={12}>
                  <Typography variant="span" color="error" component="h4">
                    Note: Please Select Class first before Subject.
                  </Typography>
                </Grid>*/}
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
              <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    inputFormat="DD-MM-YYYY"
                    label="Test Date"
                    name="TransactionDate"
                    value={selectedTest.TestDate}
                    onChange={_handleEditTestDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Session"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  size="small"
                  name="Session"
                  value={selectedTest.Session}
                  onChange={_handleOnEditChange}
                >
                  <MenuItem value="2015-2016">2015-2016</MenuItem>
                  <MenuItem value="2016-2017">2016-2017</MenuItem>
                  <MenuItem value="2017-2018">2017-2018</MenuItem>
                  <MenuItem value="2018-2019">2018-2019</MenuItem>
                  <MenuItem value="2019-2020">2019-2020</MenuItem>
                  <MenuItem value="2020-2021">2020-2021</MenuItem>
                  <MenuItem value="2021-2022">2021-2022</MenuItem>
                  <MenuItem value="2022-2023">2022-2023</MenuItem>
                  <MenuItem value="2023-2024">2023-2024</MenuItem>
                  <MenuItem value="2024-2025">2024-2025</MenuItem>
                  <MenuItem value="2025-2026">2025-2026</MenuItem>
                  <MenuItem value="2026-2027">2026-2027</MenuItem>
                  <MenuItem value="2027-2028">2027-2028</MenuItem>
                  <MenuItem value="2028-2029">2028-2029</MenuItem>
                  <MenuItem value="2029-2030">2029-2030</MenuItem>
                  <MenuItem value="2030-2031">2030-2031</MenuItem>
                  <MenuItem value="2031-2032">2031-2032</MenuItem>
                  <MenuItem value="2032-2033">2032-2033</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Test Type"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  size="small"
                  name="Testype"
                  value={selectedTest.Testype}
                  onChange={_handleOnEditChange}
                >
                  <MenuItem value="WEEKLY">WEEKLY</MenuItem>
                  <MenuItem value="MONTHLY">MONTHLY</MenuItem>
                  <MenuItem value="MID-TERM">MID-TERM</MenuItem>
                  <MenuItem value="ANNUAL">ANNUAL</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Status"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  size="small"
                  name="Status"
                  value={selectedTest.Status}
                  onChange={_handleOnEditChange}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Max Marks"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="MaxMarks"
                  value={selectedTest.MaxMarks}
                  type="number"
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Passing Percentage"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="PassingPercentage"
                  value={selectedTest.PassingPercentage}
                  type="number"
                  onChange={_handleOnEditChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Class"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  value={selectedTest.ClassID}
                  name="ClassID"
                  onChange={_handleEditClassChange}
                  required
                  size="small"
                >
                  {classes.map((item, i) => {
                    return (
                      <MenuItem key={i} value={item.Id}>
                        {item.Name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Subject"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  value={selectedTest.SubjectID}
                  name="SubjectID"
                  required
                  onChange={_handleOnEditChange}
                  size="small"
                >
                  {subjects.map((sub, i) => {
                    return (
                      <MenuItem key={i} value={sub.Id}>
                        {sub.Name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Subject Variation"
                  sx={{ display: "flex", flex: 1 }}
                  variant="outlined"
                  value={selectedTest.SubjectVariation}
                  name="SubjectVariation"
                  required
                  onChange={_handleOnEditChange}
                  size="small"
                >
                  {variations.map((variation, i) => {
                    return (
                      <MenuItem key={i} value={variation.Caption}>
                        {variation.Caption}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  loading={loading}
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Update Test
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          You are about to delete test.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="error">
            Are you sure you want to Delete this Test?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No, Cancel</Button>
          <Button color="error" onClick={_handleDeleteTest} autoFocus>
            Yes, Delete Test
          </Button>
        </DialogActions>
      </Dialog>
      <Paper>
        <Box sx={{ mt: 2, mb: 2 }}>
          {subjectVariationArray.length > 0 ? (
            subjectVariationArray.map((item) => (
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="body2">
                    <strong>Test list for {item}</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MUIDataTable
                    columns={[
                      ...columns,
                      {
                        label: "Actions",
                        name: "actions",
                        options: {
                          customBodyRenderLite: (rowDataIndex) => (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {/* Edit Icon */}
                                {accesLevel === "ADMIN" ? (
                                  <>
                                    {currentPageCRUD.delete && (
                                      <>
                                        <IconButton
                                          aria-label="edit"
                                          onClick={() => {
                                            var list = mappedTestList.filter(
                                              (row) =>
                                                row.SubjectVariation === item
                                            );
                                            handleEdit(list[rowDataIndex]);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            var list = mappedTestList.filter(
                                              (row) =>
                                                row.SubjectVariation === item
                                            );
                                            handleDelete(list[rowDataIndex]);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </>
                                    )}

                                    <IconButton
                                      aria-label="navigate"
                                      onClick={() => {
                                        var list = mappedTestList.filter(
                                          (row) => row.SubjectVariation === item
                                        );
                                        handleTestResult(list[rowDataIndex]);
                                      }}
                                    >
                                      <AddCircleOutlineIcon />
                                    </IconButton>
                                  </>
                                ) : (
                                  <IconButton
                                    aria-label="navigate"
                                    onClick={() => {
                                      var list = mappedTestList.filter(
                                        (row) => row.SubjectVariation === item
                                      );
                                      handleTestResult(list[rowDataIndex]);
                                    }}
                                  >
                                    <AddCircleOutlineIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </>
                          ),
                        },
                      },
                    ]}
                    data={mappedTestList
                      .filter((row) => row.SubjectVariation === item)
                      .map((row) => {
                        return {
                          TestDate: dayjs(row.TestDate).format("YYYY-MM-DD"),
                          Session: row.Session,
                          Testype: row.Testype,
                          MaxMarks: row.MaxMarks,
                          PassingPercentage: row.PassingPercentage,
                          SubjectName:
                            Object.keys(row.Subject).length != 0
                              ? row.Subject.SubjectName
                              : "",
                          SubjectVariation: row.SubjectVariation,
                          ClassName:
                            Object.keys(row.ClassModel).length != 0
                              ? row.ClassModel.ClassName
                              : "",
                        };
                      })}
                    options={{
                      filter: false,
                      sort: false,
                    }}
                    pagination
                    selectableRows
                    onRowClick={(rowData) => {
                      console.log("Clicked Row:", rowData);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Box component={Paper} sx={{ padding: 1, textAlign: "center" }}>
              <Typography variant="subtitle2" component="span">
                <strong>No Record Found!</strong>
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>

      {/* <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar> */}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openError}
        autoHideDuration={6000}
        onClose={snack_handleErrorClose}
      >
        <Alert
          onClose={snack_handleErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          You have no right to delete Test!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={dangerSnack}
        autoHideDuration={6000}
        onClose={snack_handleDangerClose}
      >
        <Alert
          onClose={snack_handleDangerClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
