import {
  Avatar,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Divider,
  FormControlLabel,
  Checkbox,
  Slider,
  Dialog
} from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import * as yup from "yup";
import { getClasses } from "../ClassSubjects/Classes";
import dayjs from "dayjs";
import useSession from "../../CustomHooks/useSession";
import axiosInstance, { baseURL } from "../../Api/axios.instance";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { MinioUrl } from "./../../Api/axios.instance";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const validationSchema = yup.object({
  StudentName: yup
    .string("Enter Student Name")
    .required("Student Name is required"),
  GRNO: yup.string("Enter GR NO").required("GRNO is required"),
  FathersName: yup
    .string("Enter Father's Name")
    .required("Father's Name is required"),
  MothersName: yup
    .string("Enter Mother's Name")
    .required("Mother's Name is required"),
  IsFatherNazra_Done: yup
    .string("Select if Father Nazra is Done")
    .required("Father Nazra selection is required"),
  IsMotherNazra_Done: yup
    .string("Select if Mother Nazra is Done")
    .required("Mother Nazra selection is required"),
  DOB: yup.string("Set Date of Birth").required("Date of Birth is required"),
  ClassID: yup.string("Select Class").required("Class is required"),
  ClassAtTheTimeOfAdmission: yup
    .string("Select Class At The Time Of Admission")
    .required("Class At The Time Of Admission is required"),
  Date_Of_Addmission: yup
    .string("Date of Addmission")
    .required("Date of Addmission is required"),
  Fee: yup.number("Enter Fee").required("Fee is required"),
  Gender: yup.string("Select Gender").required("Gender is required"),
  is_Syed: yup.string("Select One").required("Please select an option"),
  Applicant_Eligible: yup
    .string("Select One")
    .required("Please select an option"),
  DateofTaking: yup
    .string("Date of Undertaking")
    .required("Date of Under Taking is required"),
  NIC_Father: yup.string("Enter Father NIC").required("Father NIC is required"),
  NIC_Guardian: yup
    .string("Enter Guardian NIC")
    .required("Guardian NIC is required"),
  GRNO: yup.string("Enter GRNO").required("GRNO is required"),
  Fee: yup.string("Enter Fee").required("Fee is required"),
});

const SectionDivider = ({ text }) => {
  return (
    <Stack
      sx={{ mt: 2, mb: 2 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Divider sx={{ flex: 1, display: "flex" }} />
      <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
        {text}
      </Typography>
      <Divider sx={{ flex: 1, display: "flex" }} />
    </Stack>
  );
};

export default function AddStudents(props) {
  const [classes, setClasses] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [imgBase, setImgBase] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [snackbar, setSnackBar] = useState({
    open: false,
    message: "Test Message",
    severity: "success",
  });
  const timer = useRef(null);
  const { accesLevel, access } = useSession();
  const { state, pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const inputFile = useRef(null);
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const defaultSession = `${currentYear}-${nextYear}`;
  const formik = useFormik({
    initialValues: {
      GRNO: "",
      StudentName: "",
      FathersName: "",
      MothersName: "",
      GuardianName: "",
      GuardianRelation: "",
      Gender: "",
      DOB: dayjs().locale("en").format("YYYY-MM-DD"),
      School_code: "",
      Address: "",
      UC: "",
      Taluka: "",
      District: "",
      MotherTongue: "0",
      Ntionality: "",
      Religion: "0",
      NIC_Father: "",
      NIC_Mother: "",
      NIC_Guardian: "",
      Mother_qualification: "",
      Father_Qualification: "",
      Father_Occupation: "",
      Mother_Occupation: "",
      Present_Address: "",
      PermanentAddress: "",
      Average_Monthly_Income: "",
      Dependents: "",
      ClassID: "",
      Date_Of_Addmission: dayjs().format("YYYY-MM-DD"),
      Old_School_Name: "",
      Old_ClassName: "",
      Reason_Leaved: "",
      Parent_Cell_No: "",
      Parent_Whatssapp_No: "",
      CurrentSession: "",
      SchoolId: "",
      Siblings: 0,
      Fee: "",
      IsOrphan: "",
      image: "",
      DateofTaking: dayjs().format("YYYY-MM-DD"),
      isDropout: false,
      Dropout_Date: "",
      Gold_Value: 0,
      Silver_Value: 0,
      Cash_Value: 0,
      Comm_Stuff_Value: 0,
      Extra_Assets: 0,
      Loan: 0,
      Net_worth: 0,
      is_Syed: false,
      Applicant_Eligible: false,
      ClassAtTheTimeOfAdmission: "",
      Remarks: "",
      IsMotherNazra_Done: "",
      IsFatherNazra_Done: "",
      ActiveSession: defaultSession,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
        console.log("formData", values);
      }
      try {
        const url = pathname.includes("edit")
          ? `/students/${state.SID}`
          : pathname.includes("dropout")
          ? `/students/${state.SID}`
          : "/students";
        const { status, data } = await axiosInstance[
          pathname.includes("edit")
            ? "put"
            : pathname.includes("dropout")
            ? "put"
            : "post"
        ](url, formData, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        });
        if (status === 200) {
          setSnackBar((prevState) => {
            return {
              open: true,
              message: pathname.includes("edit")
                ? "Student has been updated"
                : pathname.includes("dropout")
                ? "Student has been dropout"
                : "Student has been registered.",
              severity: "success",
            };
          });

          timer.current = setTimeout(async () => {
            if (pathname.includes("edit")) {
              const { id } = params;
              const { data, status } = await axiosInstance.get(
                `/students/${id}`
              );
              const { data: studentData } = data;
              if (status === 200) {
                navigate(`../details/${id}`, {
                  state: {
                    studentData,
                  },
                  replace: true,
                });
              }
              return;
            }
            if (pathname.includes("dropout")) {
              const { id } = params;
              const { data, status } = await axiosInstance.get(
                `/students/${id}`
              );
              const { data: studentData } = data;
              if (status === 200) {
                navigate(`../details/${id}`, {
                  state: {
                    studentData,
                  },
                  replace: true,
                });
              }
              return;
            }
            navigate("../", {
              state: null,
            });
          }, 3000);
        }
      } catch (error) {
        setSnackBar((prevState) => {
          return {
            open: true,
            message:
              "There was an error performing this operation. Please contact developer.",
            severity: "error",
          };
        });
      }
    },
  });
  useEffect(() => {
    if (accesLevel === "SCHOOL") {
      const accessObject = Object.assign({}, access);
      formik.setFieldValue("SchoolId", accessObject[0].SchoolId);
      formik.setFieldValue("School_code", accessObject[0].School.NameOfSchool);
    }
    if (pathname.includes("edit")) {
      if (state !== null && state !== undefined) {
        for (let value in formik.values) {
          if (
            value === "DOB" ||
            value === "Date_Of_Addmission" ||
            value === "DateofTaking" ||
            value === "Dropout_Date"
          ) {
            formik.setFieldValue(
              value,
              dayjs(state[value]).format("YYYY-MM-DD")
            );
          } else if (
            value === "is_Syed" ||
            value === "Applicant_Eligible" ||
            value === "Gold_Value" ||
            value === "Silver_Value" ||
            value === "Cash_Value" ||
            value === "Loan" ||
            value === "Net_worth" ||
            value === "Extra_Assets" ||
            value === "Comm_Stuff_Value"
          ) {
            if (state.UnderTaking !== null) {
              console.log("not null");
              formik.setFieldValue("is_Syed", state?.UnderTaking?.is_Syed);
              formik.setFieldValue(
                "Applicant_Eligible",
                state?.UnderTaking?.Applicant_Eligible
              );
              formik.setFieldValue(
                "Gold_Value",
                state?.UnderTaking?.Gold_Value
              );
              formik.setFieldValue(
                "Silver_Value",
                state?.UnderTaking?.Silver_Value
              );
              formik.setFieldValue(
                "Cash_Value",
                state?.UnderTaking?.Cash_Value
              );
              formik.setFieldValue("Loan", state?.UnderTaking?.Loan);
              formik.setFieldValue("Net_worth", state?.UnderTaking?.Net_worth);
              formik.setFieldValue(
                "Extra_Assets",
                state?.UnderTaking?.Extra_Assets
              );
              formik.setFieldValue(
                "Comm_Stuff_Value",
                state?.UnderTaking?.Comm_Stuff_Value
              );
              formik.setFieldValue(
                "DateofTaking",
                dayjs(state?.UnderTaking?.DateofTaking).format("YYYY-MM-DD")
              );
            }
          } else {
            formik.setFieldValue(value, state[value]);
          }
        }

        if (state.image !== null || state.image !== undefined) {
          setImgBase(`${MinioUrl}/students/${state.image}`);
        } else {
          setImgBase("");
        }
      } else {
        const { id } = params;
        (async () => {
          try {
            const { data, status } = await axiosInstance.get(`/students/${id}`);
            if (status === 200) {
              const { data: studentData } = data;
              console.log("FormData", studentData);
              for (let value in formik.values) {
                if (
                  value === "DOB" ||
                  value === "Date_Of_Addmission" ||
                  value === "DateofTaking" ||
                  value === "Dropout_Date"
                ) {
                  formik.setFieldValue(
                    value,
                    dayjs(studentData[value]).format("YYYY-MM-DD")
                  );
                  console.log("Value and Data", value, studentData);
                } else if (
                  value === "is_Syed" ||
                  value === "Applicant_Eligible" ||
                  value === "Gold_Value" ||
                  value === "Silver_Value" ||
                  value === "Cash_Value" ||
                  value === "Loan" ||
                  value === "Net_worth" ||
                  value === "Extra_Assets" ||
                  value === "Comm_Stuff_Value"
                ) {
                  if (studentData.UnderTaking !== null) {
                    formik.setFieldValue(
                      "is_Syed",
                      studentData?.UnderTaking?.is_Syed
                    );
                    formik.setFieldValue(
                      "Applicant_Eligible",
                      studentData?.UnderTaking?.Applicant_Eligible
                    );
                    formik.setFieldValue(
                      "Gold_Value",
                      studentData?.UnderTaking?.Gold_Value
                    );
                    formik.setFieldValue(
                      "Silver_Value",
                      studentData?.UnderTaking?.Silver_Value
                    );
                    formik.setFieldValue(
                      "Cash_Value",
                      studentData?.UnderTaking?.Cash_Value
                    );
                    formik.setFieldValue(
                      "Loan",
                      studentData?.UnderTaking?.Loan
                    );
                    formik.setFieldValue(
                      "Net_worth",
                      studentData?.UnderTaking?.Net_worth
                    );
                    formik.setFieldValue(
                      "Extra_Assets",
                      studentData?.UnderTaking?.Extra_Assets
                    );
                    formik.setFieldValue(
                      "Comm_Stuff_Value",
                      studentData?.UnderTaking?.Comm_Stuff_Value
                    );
                    formik.setFieldValue(
                      "DateofTaking",
                      dayjs(studentData?.UnderTaking?.DateofTaking)
                    );
                  }
                } else {
                  formik.setFieldValue(value, studentData[value]);
                  console.log("Value and Data", value, studentData);
                }
              }

              if (
                studentData.image !== null ||
                studentData.image !== undefined
              ) {
                setImgBase("");
              } else {
                setImgBase(`${MinioUrl}/students/${studentData.image}`);
              }
            }
          } catch (error) {
            console.log(error);
            return null;
          }
        })();
      }
    }
    if (pathname.includes("dropout")) {
      if (state !== null && state !== undefined) {
        for (let value in formik.values) {
          if (
            value === "DOB" ||
            value === "Date_Of_Addmission" ||
            value === "DateofTaking" ||
            value === "Dropout_Date"
          ) {
            formik.setFieldValue(
              value,
              dayjs(state[value]).format("YYYY-MM-DD")
            );
          } else if (
            value === "is_Syed" ||
            value === "Applicant_Eligible" ||
            value === "Gold_Value" ||
            value === "Silver_Value" ||
            value === "Cash_Value" ||
            value === "Loan" ||
            value === "Net_worth" ||
            value === "Extra_Assets" ||
            value === "Comm_Stuff_Value"
          ) {
            if (state.UnderTaking !== null) {
              console.log("not null");
              formik.setFieldValue("is_Syed", state?.UnderTaking?.is_Syed);
              formik.setFieldValue(
                "Applicant_Eligible",
                state?.UnderTaking?.Applicant_Eligible
              );
              formik.setFieldValue(
                "Gold_Value",
                state?.UnderTaking?.Gold_Value
              );
              formik.setFieldValue(
                "Silver_Value",
                state?.UnderTaking?.Silver_Value
              );
              formik.setFieldValue(
                "Cash_Value",
                state?.UnderTaking?.Cash_Value
              );
              formik.setFieldValue("Loan", state?.UnderTaking?.Loan);
              formik.setFieldValue("Net_worth", state?.UnderTaking?.Net_worth);
              formik.setFieldValue(
                "Extra_Assets",
                state?.UnderTaking?.Extra_Assets
              );
              formik.setFieldValue(
                "Comm_Stuff_Value",
                state?.UnderTaking?.Comm_Stuff_Value
              );
              formik.setFieldValue(
                "DateofTaking",
                dayjs(state?.UnderTaking?.DateofTaking).format("YYYY-MM-DD")
              );
            }
          } else {
            formik.setFieldValue(value, state[value]);
          }
        }

        if (state.image !== null || state.image !== undefined) {
          setImgBase(`${MinioUrl}/students/${state.image}`);
        } else {
          setImgBase("");
        }
      } else {
        const { id } = params;
        (async () => {
          try {
            const { data, status } = await axiosInstance.get(`/students/${id}`);
            if (status === 200) {
              const { data: studentData } = data;
              console.log("FormData", studentData);
              for (let value in formik.values) {
                if (
                  value === "DOB" ||
                  value === "Date_Of_Addmission" ||
                  value === "DateofTaking" ||
                  value === "Dropout_Date"
                ) {
                  formik.setFieldValue(
                    value,
                    dayjs(studentData[value]).format("YYYY-MM-DD")
                  );
                  console.log("Value and Data", value, studentData);
                } else if (
                  value === "is_Syed" ||
                  value === "Applicant_Eligible" ||
                  value === "Gold_Value" ||
                  value === "Silver_Value" ||
                  value === "Cash_Value" ||
                  value === "Loan" ||
                  value === "Net_worth" ||
                  value === "Extra_Assets" ||
                  value === "Comm_Stuff_Value"
                ) {
                  if (studentData.UnderTaking !== null) {
                    formik.setFieldValue(
                      "is_Syed",
                      studentData?.UnderTaking?.is_Syed
                    );
                    formik.setFieldValue(
                      "Applicant_Eligible",
                      studentData?.UnderTaking?.Applicant_Eligible
                    );
                    formik.setFieldValue(
                      "Gold_Value",
                      studentData?.UnderTaking?.Gold_Value
                    );
                    formik.setFieldValue(
                      "Silver_Value",
                      studentData?.UnderTaking?.Silver_Value
                    );
                    formik.setFieldValue(
                      "Cash_Value",
                      studentData?.UnderTaking?.Cash_Value
                    );
                    formik.setFieldValue(
                      "Loan",
                      studentData?.UnderTaking?.Loan
                    );
                    formik.setFieldValue(
                      "Net_worth",
                      studentData?.UnderTaking?.Net_worth
                    );
                    formik.setFieldValue(
                      "Extra_Assets",
                      studentData?.UnderTaking?.Extra_Assets
                    );
                    formik.setFieldValue(
                      "Comm_Stuff_Value",
                      studentData?.UnderTaking?.Comm_Stuff_Value
                    );
                    formik.setFieldValue(
                      "DateofTaking",
                      dayjs(studentData?.UnderTaking?.DateofTaking)
                    );
                  }
                } else {
                  formik.setFieldValue(value, studentData[value]);
                  console.log("Value and Data", value, studentData);
                }
              }

              if (
                studentData.image !== null ||
                studentData.image !== undefined
              ) {
                setImgBase("");
              } else {
                setImgBase(`${MinioUrl}/students/${studentData.image}`);
              }
            }
          } catch (error) {
            console.log(error);
            return null;
          }
        })();
      }
    }
    _getClasses();
    _fetchReasonOfLeavingVariables();
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  const _fetchReasonOfLeavingVariables = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        "/variables/variables-leaved-reasons"
      );
      if (data.data.length > 0) {
        setReasons(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _getClasses = async () => {
    try {
      const response = await getClasses();
      setClasses(response.data);
      // console.log("classes", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _openFileDialog = () => {
    if (imgBase !== "") {
      setImgBase("");
      formik.setFieldValue("image", "");
      return;
    }
    inputFile.current.click();
  };
  const _onImageSelect = async (e) => {
    const file = e.currentTarget.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = await function (event) {
      setImgBase(event.target?.result);
    };
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBar({
      open: false,
      message: "",
    });
  };
  const _onCancelForm = () => {
    setCancelDialog(true);
  };
  const handleDialogClose = () => {
    setCancelDialog(false);
  };
  const _cancelSubmittion = () => {
    if (!pathname.includes("edit")) {
      formik.resetForm();
    }
    if (!pathname.includes("dropout")) {
      formik.resetForm();
    }
    navigate("/students", {
      replace: true,
    });
  };

  return (
    <Box>
      <Stack sx={{ mb: 2 }}>
        <Typography variant="h6" component="h1">
          {pathname.includes("dropout")
            ? "Student Dropout Form"
            : "Student Registration Form"}
        </Typography>
      </Stack>
      <Box
        flexGrow={1}
        component="form"
        onSubmit={formik.handleSubmit}
        encType="multipart/form-data"
      >
        {!pathname.includes("dropout") && (
          <>
            <SectionDivider text="Student Information" />
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <Stack>
                <Paper sx={{ p: 1 }} square>
                  <Avatar
                    variant="square"
                    src={
                      imgBase === ""
                        ? "https://www.pngitem.com/pimgs/m/99-998739_dale-engen-person-placeholder-hd-png-download.png"
                        : imgBase
                    }
                    sx={{ width: 150, height: 150, objectFit: "cover" }}
                  />
                  <Stack
                    flexDirection={"row"}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 1 }}
                    direction={{ xs: "column", sm: "row", md: "row" }}
                    spacing={{ xs: 2, sm: 2, md: 4 }}
                  >
                    <Button
                      onClick={_openFileDialog}
                      size="small"
                      variant="text"
                      disableElevation
                      color={imgBase === null ? "primary" : "error"}
                    >
                      {imgBase === "" ? "Select Picture" : "Change Picture"}
                    </Button>
                    <input
                      name="image"
                      type="file"
                      ref={inputFile}
                      onChange={(e) => {
                        const file = e.target.files[0];

                        if (file) {
                          if (file.size > 2 * 1024 * 1024) {
                            alert("File size must be less than 2MB.");
                            return;
                          }

                          formik.setFieldValue("image", file);
                          _onImageSelect(e);
                        }
                      }}
                      hidden
                    />
                  </Stack>
                </Paper>
              </Stack>
              <Stack sx={{ ml: 2 }} flexGrow={1}>
                <Stack
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={{ xs: 2, sm: 2, md: 4 }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.StudentName}
                    error={
                      formik.touched.StudentName &&
                      Boolean(formik.errors.StudentName)
                    }
                    helperText={
                      formik.touched.StudentName && formik.errors.StudentName
                    }
                    name="StudentName"
                    label="Student Name"
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.FathersName}
                    error={
                      formik.touched.FathersName &&
                      Boolean(formik.errors.FathersName)
                    }
                    helperText={
                      formik.touched.FathersName && formik.errors.FathersName
                    }
                    label="Father's Name"
                    name="FathersName"
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.MothersName}
                    error={
                      formik.touched.MothersName &&
                      Boolean(formik.errors.MothersName)
                    }
                    helperText={
                      formik.touched.MothersName && formik.errors.MothersName
                    }
                    label="Mother's Name"
                    name="MothersName"
                  />
                </Stack>
                <Stack
                  sx={{ mt: 3 }}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={{ xs: 2, sm: 2, md: 4 }}
                >
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    label="Guardian Name"
                    onChange={formik.handleChange}
                    value={formik.values.GuardianName}
                    name="GuardianName"
                  />
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    label="Guardian Relation"
                    onChange={formik.handleChange}
                    value={formik.values.GuardianRelation}
                    name="GuardianRelation"
                  />
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Gender"
                    sx={{ display: "flex", flex: 1 }}
                    error={
                      formik.touched.Gender && Boolean(formik.errors.Gender)
                    }
                    helperText={formik.touched.Gender && formik.errors.Gender}
                    onChange={formik.handleChange}
                    value={formik.values.Gender}
                    variant="outlined"
                    size="small"
                    name="Gender"
                    defaultValue="Select Gender"
                  >
                    <MenuItem key={"0"} value={"Select Gender"}>
                      Select Gender
                    </MenuItem>
                    <MenuItem key={"1"} value={"MALE"}>
                      MALE
                    </MenuItem>
                    <MenuItem key={"2"} value={"FEMALE"}>
                      FEMALE
                    </MenuItem>
                  </TextField>
                </Stack>
                <Stack
                  sx={{ mt: 3 }}
                  flexGrow={1}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={{ xs: 2, sm: 2, md: 4 }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD-MM-YYYY"
                      value={formik.values.DOB}
                      label="Date of Birth"
                      onChange={(newValue) =>
                        formik.setFieldValue(
                          "DOB",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          value={formik.values.DOB}
                          error={
                            formik.touched.DOB && Boolean(formik.errors.DOB)
                          }
                          helperText={formik.touched.DOB && formik.errors.DOB}
                          name="DOB"
                          sx={{ display: "flex", flex: 1 }}
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <TextField
                    size="small"
                    label="Address"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={(e) => {
                      formik.setFieldValue("Address", e.target.value);
                      formik.setFieldValue("Present_Address", e.target.value);
                      formik.setFieldValue("PermanentAddress", e.target.value);
                    }}
                    value={formik.values.Address}
                    name="Address"
                  />
                </Stack>
                <Stack
                  sx={{ mt: 3 }}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={{ xs: 2, sm: 2, md: 4 }}
                >
                  <TextField
                    size="small"
                    label="School Code"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.School_code}
                    name="School_code"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    size="small"
                    label="UC"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.UC}
                    name="UC"
                  />
                  <TextField
                    size="small"
                    label="Taluka"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.Taluka}
                    name="Taluka"
                  />
                  <TextField
                    size="small"
                    label="District"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.District}
                    name="District"
                  />
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Mother Toungue"
                    sx={{ display: "flex", flex: 1 }}
                    error={
                      formik.touched.MotherTongue &&
                      Boolean(formik.errors.MotherTongue)
                    }
                    helperText={
                      formik.touched.MotherTongue && formik.errors.MotherTongue
                    }
                    onChange={formik.handleChange}
                    value={formik.values.MotherTongue}
                    name="MotherTongue"
                    variant="outlined"
                    size="small"
                    // defaultValue="Select Religion"
                  >
                    <MenuItem key={"0"} value={"0"}>
                      Urdu
                    </MenuItem>
                    <MenuItem key={"1"} value={"1"}>
                      Sindhi
                    </MenuItem>
                    <MenuItem key={"2"} value={"2"}>
                      Panjabi
                    </MenuItem>
                    <MenuItem key={"3"} value={"3"}>
                      Pashto
                    </MenuItem>
                    <MenuItem key={"4"} value={"4"}>
                      Saraiki
                    </MenuItem>
                    <MenuItem key={"5"} value={"5"}>
                      Balochi
                    </MenuItem>
                    <MenuItem key={"6"} value={"6"}>
                      Hindko
                    </MenuItem>
                    <MenuItem key={"7"} value={"Other"}>
                      Other
                    </MenuItem>
                  </TextField>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                id="standard-select-currency"
                select
                label="Nationality"
                sx={{ display: "flex", flex: 1 }}
                error={
                  formik.touched.Ntionality && Boolean(formik.errors.Ntionality)
                }
                helperText={
                  formik.touched.Ntionality && formik.errors.Ntionality
                }
                onChange={formik.handleChange}
                value={formik.values.Ntionality}
                name="Ntionality"
                variant="outlined"
                size="small"
                // defaultValue="Select Religion"
              >
                <MenuItem key={"0"} value={"Pakistani"}>
                  Pakistani
                </MenuItem>
                <MenuItem key={"1"} value={"Barmi"}>
                  Barmi
                </MenuItem>
                <MenuItem key={"2"} value={"Afghani"}>
                  Afghani
                </MenuItem>
              </TextField>
              <TextField
                id="standard-select-currency"
                select
                label="Religion"
                sx={{ display: "flex", flex: 1 }}
                error={
                  formik.touched.Religion && Boolean(formik.errors.Religion)
                }
                helperText={formik.touched.Religion && formik.errors.Religion}
                onChange={formik.handleChange}
                value={formik.values.Religion}
                name="Religion"
                variant="outlined"
                size="small"
              >
                <MenuItem key={"0"} value={"0"}>
                  Islam
                </MenuItem>
                <MenuItem key={"1"} value={"1"}>
                  Christianity
                </MenuItem>
                <MenuItem key={"2"} value={"2"}>
                  Judaism
                </MenuItem>
                <MenuItem key={"3"} value={"3"}>
                  Buddhism
                </MenuItem>
                <MenuItem key={"4"} value={"4"}>
                  Hinduism
                </MenuItem>
                <MenuItem key={"5"} value={"5"}>
                  Sikhism
                </MenuItem>
                <MenuItem key={"5"} value={"5"}>
                  Others
                </MenuItem>
              </TextField>
              <TextField
                id="standard-select-currency"
                select
                label="Is Orphan?"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.IsOrphan}
                variant="outlined"
                size="small"
                name="IsOrphan"
              >
                <MenuItem key={"0"} value={"make-selection"}>
                  Select That apply
                </MenuItem>
                <MenuItem key={"1"} value={true}>
                  YES
                </MenuItem>
                <MenuItem key={"2"} value={false}>
                  NO
                </MenuItem>
              </TextField>
              <TextField
                size="small"
                label="Old School Name"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Old_School_Name}
                name="Old_School_Name"
              />
              <TextField
                size="small"
                label="Old Class Name"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Old_ClassName}
                name="Old_ClassName"
              />
            </Stack>
            <SectionDivider text="Guardian's Information" />
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.NIC_Father}
                name="NIC_Father"
                label="Father's CNIC"
                type="number"
                error={
                  formik.touched.NIC_Father && Boolean(formik.errors.NIC_Father)
                }
                helperText={
                  formik.touched.NIC_Father && formik.errors.NIC_Father
                }
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.NIC_Mother}
                name="NIC_Mother"
                label="Mother's CNIC"
                type="number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                name="NIC_Guardian"
                onChange={formik.handleChange}
                value={formik.values.NIC_Guardian}
                label="Guardian's CNIC"
                type="number"
                error={
                  formik.touched.NIC_Guardian &&
                  Boolean(formik.errors.NIC_Guardian)
                }
                helperText={
                  formik.touched.NIC_Guardian && formik.errors.NIC_Guardian
                }
              />
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                id="standard-select-currency"
                select
                label="Father's Qualification"
                sx={{ display: "flex", flex: 1 }}
                error={
                  formik.touched.Father_Qualification &&
                  Boolean(formik.errors.Father_Qualification)
                }
                helperText={
                  formik.touched.Father_Qualification &&
                  formik.errors.Father_Qualification
                }
                onChange={formik.handleChange}
                value={formik.values.Father_Qualification}
                variant="outlined"
                size="small"
                name="Father_Qualification"
                defaultValue="Select Qualification"
              >
                <MenuItem key={"0"} value={"None"}>
                  None
                </MenuItem>
                <MenuItem key={"1"} value={"Primary"}>
                  Primary
                </MenuItem>
                <MenuItem key={"2"} value={"Middle"}>
                  Middle
                </MenuItem>
                <MenuItem key={"3"} value={"Matric"}>
                  Matric
                </MenuItem>
                <MenuItem key={"3"} value={"Inter"}>
                  Inter
                </MenuItem>
                <MenuItem key={"4"} value={"BEd"}>
                  BEd
                </MenuItem>
                <MenuItem key={"4"} value={"BA"}>
                  BA
                </MenuItem>
                <MenuItem key={"4"} value={"MA"}>
                  MA
                </MenuItem>
              </TextField>
              <TextField
                id="standard-select-currency"
                select
                label="Mother's Qualification"
                sx={{ display: "flex", flex: 1 }}
                error={
                  formik.touched.Mother_qualification &&
                  Boolean(formik.errors.Mother_qualification)
                }
                helperText={
                  formik.touched.Mother_qualification &&
                  formik.errors.Mother_qualification
                }
                onChange={formik.handleChange}
                value={formik.values.Mother_qualification}
                variant="outlined"
                size="small"
                name="Mother_qualification"
                defaultValue="Select Qualification"
              >
                <MenuItem key={"0"} value={"None"}>
                  None
                </MenuItem>
                <MenuItem key={"1"} value={"Primary"}>
                  Primary
                </MenuItem>
                <MenuItem key={"2"} value={"Middle"}>
                  Middle
                </MenuItem>
                <MenuItem key={"3"} value={"Matric"}>
                  Matric
                </MenuItem>
                <MenuItem key={"3"} value={"Inter"}>
                  Inter
                </MenuItem>
                <MenuItem key={"4"} value={"BEd"}>
                  BEd
                </MenuItem>
                <MenuItem key={"4"} value={"BA"}>
                  BA
                </MenuItem>
                <MenuItem key={"4"} value={"MA"}>
                  MA
                </MenuItem>
              </TextField>
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Siblings}
                name="Siblings"
                label="Siblings"
                type="number"
              />
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                id="standard-select-currency"
                select
                label="Father Nazra Done?"
                sx={{ display: "flex", flex: 1 }}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.IsFatherNazra_Done}
                error={
                  formik.touched.IsFatherNazra_Done &&
                  Boolean(formik.errors.IsFatherNazra_Done)
                }
                helperText={
                  formik.touched.IsFatherNazra_Done &&
                  formik.errors.IsFatherNazra_Done
                }
                name="IsFatherNazra_Done"
              >
                <MenuItem key={"0"} value={"make-selection"}>
                  Select That apply
                </MenuItem>
                <MenuItem key={"1"} value={"YES"}>
                  YES
                </MenuItem>
                <MenuItem key={"2"} value={"NO"}>
                  NO
                </MenuItem>
              </TextField>
              <TextField
                id="standard-select-currency"
                select
                label="Mother Nazra Done?"
                sx={{ display: "flex", flex: 1 }}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.IsMotherNazra_Done}
                error={
                  formik.touched.IsMotherNazra_Done &&
                  Boolean(formik.errors.IsMotherNazra_Done)
                }
                helperText={
                  formik.touched.IsMotherNazra_Done &&
                  formik.errors.IsMotherNazra_Done
                }
                name="IsMotherNazra_Done"
              >
                <MenuItem key={"0"} value={"make-selection"}>
                  Select That apply
                </MenuItem>
                <MenuItem key={"1"} value={"YES"}>
                  YES
                </MenuItem>
                <MenuItem key={"2"} value={"NO"}>
                  NO
                </MenuItem>
              </TextField>
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Parent_Cell_No}
                name="Parent_Cell_No"
                type="number"
                label="Parent Cell Number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Parent_Whatssapp_No}
                name="Parent_Whatssapp_No"
                type="number"
                label="Parent Whatsapp Number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Father_Occupation}
                name="Father_Occupation"
                label="Father's Occupation"
              />
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Mother_Occupation}
                name="Mother_Occupation"
                label="Mother's Occupation"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Average_Monthly_Income}
                type="number"
                name="Average_Monthly_Income"
                label="Average Monthly Income"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Dependents}
                type="number"
                name="Dependents"
                label="Dependents"
              />
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Present_Address}
                name="Present_Address"
                label="Present Address"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.PermanentAddress}
                name="PermanentAddress"
                label="Permenant Address"
              />
            </Stack>
            <SectionDivider text="School Information" />
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                onChange={formik.handleChange}
                value={formik.values.GRNO}
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                name="GRNO"
                type="number"
                label="GR NO"
                error={formik.touched.GRNO && Boolean(formik.errors.GRNO)}
                helperText={formik.touched.GRNO && formik.errors.GRNO}
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Fee}
                error={formik.touched.Fee && Boolean(formik.errors.Fee)}
                helperText={formik.touched.Fee && formik.errors.Fee}
                type="number"
                name="Fee"
                label="Fee"
              />
              <TextField
                id="standard-select-currency"
                select
                label="Session"
                sx={{ display: "flex", flex: 1, mb: 2 }}
                variant="outlined"
                name="ActiveSession"
                required
                onChange={formik.handleChange}
                value={formik.values.ActiveSession}
                error={
                  formik.touched.ActiveSession &&
                  Boolean(formik.errors.ActiveSession)
                }
                helperText={
                  formik.touched.ActiveSession && formik.errors.ActiveSession
                }
                size="small"
              >
                <MenuItem value="2015-2016">2015-2016</MenuItem>
                <MenuItem value="2016-2017">2016-2017</MenuItem>
                <MenuItem value="2017-2018">2017-2018</MenuItem>
                <MenuItem value="2018-2019">2018-2019</MenuItem>
                <MenuItem value="2019-2020">2019-2020</MenuItem>
                <MenuItem value="2020-2021">2020-2021</MenuItem>
                <MenuItem value="2021-2022">2021-2022</MenuItem>
                <MenuItem value="2022-2023">2022-2023</MenuItem>
                <MenuItem value="2023-2024">2023-2024</MenuItem>
                <MenuItem value="2024-2025">2024-2025</MenuItem>
                <MenuItem value="2025-2026">2025-2026</MenuItem>
                <MenuItem value="2026-2027">2026-2027</MenuItem>
                <MenuItem value="2027-2028">2027-2028</MenuItem>
                <MenuItem value="2028-2029">2028-2029</MenuItem>
                <MenuItem value="2029-2030">2029-2030</MenuItem>
                <MenuItem value="2030-2031">2030-2031</MenuItem>
                <MenuItem value="2031-2032">2031-2032</MenuItem>
                <MenuItem value="2032-2033">2032-2033</MenuItem>
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={formik.values.Date_Of_Addmission}
                  label="Date of Admission"
                  onChange={(newValue) =>
                    formik.setFieldValue(
                      "Date_Of_Addmission",
                      dayjs(newValue).format("YYYY-MM-DD")
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      value={formik.values.Date_Of_Addmission}
                      error={
                        formik.touched.Date_Of_Addmission &&
                        Boolean(formik.errors.Date_Of_Addmission)
                      }
                      helperText={
                        formik.touched.Date_Of_Addmission &&
                        formik.errors.Date_Of_Addmission
                      }
                      name="Date_Of_Addmission"
                      sx={{ display: "flex", flex: 1 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                id="standard-select-currency"
                select
                label="Current Class"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.ClassID}
                variant="outlined"
                size="small"
                error={formik.touched.ClassID && Boolean(formik.errors.ClassID)}
                helperText={formik.touched.ClassID && formik.errors.ClassID}
                name="ClassID"
              >
                {classes.map((item, index) => (
                  <MenuItem key={String(index)} value={item.ID}>
                    {item.ClassName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="standard-select-currency"
                select
                label="Class at the time of Admission"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.ClassAtTheTimeOfAdmission}
                variant="outlined"
                size="small"
                error={
                  formik.touched.ClassAtTheTimeOfAdmission &&
                  Boolean(formik.errors.ClassAtTheTimeOfAdmission)
                }
                helperText={
                  formik.touched.ClassAtTheTimeOfAdmission &&
                  formik.errors.ClassAtTheTimeOfAdmission
                }
                name="ClassAtTheTimeOfAdmission"
              >
                {classes.map((item, index) => (
                  <MenuItem key={String(index)} value={item.ID}>
                    {item.ClassName}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Remarks"
                name="Remarks"
                value={formik.values.Remarks}
                onChange={formik.handleChange}
                style={{ width: "100%", padding: 15 }}
              />
            </Stack>{" "}
          </>
        )}
        {pathname.includes("edit") ||
          (pathname.includes("dropout") && (
            <Stack>
              <SectionDivider text="Dropout Information" />
              <Stack
                sx={{ mt: 3 }}
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                  <TextField
                    size="small"
                    variant="outlined"
                    sx={{ display: "flex", flex: 1 }}
                    onChange={formik.handleChange}
                    value={formik.values.StudentName}
                    error={
                      formik.touched.StudentName &&
                      Boolean(formik.errors.StudentName)
                    }
                    helperText={
                      formik.touched.StudentName && formik.errors.StudentName
                    }
                    name="StudentName"
                    label="Student Name"
                    disabled
                  />
              </Stack>
              <Stack
                sx={{ mt: 3 }}
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <FormControlLabel
                  label="If you want to drop out student please checked this checkbox"
                  value={formik.isDropout}
                  name="isDropout"
                  control={<Checkbox checked={formik.isDropout} />}
                  onChange={(e) => {
                    formik.setFieldValue("isDropout", e.target.checked);
                  }}
                />
              </Stack>
              <Stack
                sx={{ mt: 3 }}
                direction={{ xs: "column", sm: "row", md: "row" }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
              >
                <TextField
                  id="standard-select-currency"
                  select
                  label="Reason of leaving"
                  sx={{ display: "flex", flex: 1 }}
                  onChange={(e) => {
                    formik.setFieldValue("Reason_Leaved", e.target.value);
                  }}
                  value={formik.values.Reason_Leaved}
                  name="Reason_Leaved"
                  disabled={!formik.values.isDropout}
                  required={formik.values.isDropout}
                  variant="outlined"
                  size="small"
                  error={
                    formik.touched.Reason_Leaved &&
                    Boolean(formik.errors.Reason_Leaved)
                  }
                  helperText={
                    formik.touched.Reason_Leaved && formik.errors.Reason_Leaved
                  }
                >
                  {reasons.map((reason, index) => (
                    <MenuItem key={String(index)} value={reason.Caption}>
                      {reason.Caption}
                    </MenuItem>
                  ))}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={formik.values.Dropout_Date}
                    label="Dropout Date"
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={!formik.values.isDropout}
                    required={formik.values.isDropout}
                    onChange={(newValue) =>
                      formik.setFieldValue(
                        "Dropout_Date",
                        dayjs(newValue).format("YYYY-MM-DD")
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        value={formik.values.Dropout_Date}
                        error={
                          formik.touched.Dropout_Date &&
                          Boolean(formik.errors.Dropout_Date)
                        }
                        helperText={
                          formik.touched.Dropout_Date &&
                          formik.errors.Dropout_Date
                        }
                        name="Dropout_Date"
                        sx={{ display: "flex", flex: 1 }}
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>
          ))}

        {!pathname.includes("dropout") && (
          <>
            <SectionDivider text="Under Taking" />
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                id="standard-select-currency"
                select
                label="Is Syed?"
                sx={{ display: "flex", flex: 1 }}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.is_Syed}
                error={formik.touched.is_Syed && Boolean(formik.errors.is_Syed)}
                helperText={formik.touched.is_Syed && formik.errors.is_Syed}
                name="is_Syed"
              >
                <MenuItem key={"0"} value={"make-selection"}>
                  Select That apply
                </MenuItem>
                <MenuItem key={"1"} value={true}>
                  YES
                </MenuItem>
                <MenuItem key={"2"} value={false}>
                  NO
                </MenuItem>
              </TextField>
              <TextField
                id="standard-select-currency"
                select
                label="Is Eligible?"
                sx={{ display: "flex", flex: 1 }}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                value={formik.values.Applicant_Eligible}
                error={
                  formik.touched.Applicant_Eligible &&
                  Boolean(formik.errors.Applicant_Eligible)
                }
                helperText={
                  formik.touched.Applicant_Eligible &&
                  formik.errors.Applicant_Eligible
                }
                name="Applicant_Eligible"
              >
                <MenuItem key={"0"} value={"make-selection"}>
                  Select That apply
                </MenuItem>
                <MenuItem key={"1"} value={true}>
                  YES
                </MenuItem>
                <MenuItem key={"2"} value={false}>
                  NO
                </MenuItem>
              </TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={formik.values.DateofTaking}
                  label="Date of Undertaking"
                  onChange={(newValue) =>
                    formik.setFieldValue(
                      "DateofTaking",
                      dayjs(newValue).format("YYYY-MM-DD")
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      value={formik.values.DateofTaking}
                      error={
                        formik.touched.DateofTaking &&
                        Boolean(formik.errors.DateofTaking)
                      }
                      helperText={
                        formik.touched.DateofTaking &&
                        formik.errors.DateofTaking
                      }
                      name="DateofTaking"
                      sx={{ display: "flex", flex: 1 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Gold_Value}
                name="Gold_Value"
                label="Gold Value"
                type="number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Silver_Value}
                name="Silver_Value"
                label="Silver Value"
                type="number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Cash_Value}
                name="Cash_Value"
                label="Cash Value"
                type="number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Comm_Stuff_Value}
                name="Comm_Stuff_Value"
                label="Commercial Stuff Value"
                type="number"
              />
            </Stack>
            <Stack
              sx={{ mt: 3 }}
              direction={{ xs: "column", sm: "row", md: "row" }}
              spacing={{ xs: 2, sm: 2, md: 4 }}
            >
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Extra_Assets}
                name="Extra_Assets"
                label="Extra Assets"
                type="number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Loan}
                name="Loan"
                label="Loan"
                type="number"
              />
              <TextField
                size="small"
                variant="outlined"
                sx={{ display: "flex", flex: 1 }}
                onChange={formik.handleChange}
                value={formik.values.Net_worth}
                name="Net_worth"
                label="Net Worth"
                type="number"
              />
            </Stack>
          </>
        )}
        <Stack sx={{ mt: 2 }} flexDirection="row">
          <Button
            color="error"
            disabled={formik.isSubmitting}
            type="button"
            onClick={_onCancelForm}
            variant="contained"
            fullWidth
          >
            Cancel
          </Button>
          <Box sx={{ mx: 1 }} />
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
            variant="contained"
            fullWidth
          >
            {pathname.includes("edit")
              ? "Save Changes"
              : pathname.includes("dropout")
              ? "Save Changes"
              : "Create Student Registration"}
          </LoadingButton>
        </Stack>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={cancelDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to cancel?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cancelling this operation will remove all the data from this form
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={_cancelSubmittion} autoFocus>
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
